<template>
    <section class="proveedores-ver-informacion container-fluid overflow-auto custom-scroll mt-2">
        <!-- opcion 1 datos -->
        <titulo-divisor titulo="Datos adicionales" />
        <div class="row mx-0">
            <card-dato-adicional name="Nombre del proveedor" :descripcion="detallesProveedor.nombre || '-'" />
            <card-dato-adicional name="Contacto" :descripcion="detallesProveedor.responsable || '-'" />
            <card-dato-adicional name="Teléfono" :descripcion="detallesProveedor.telefono || '-'" />
            <card-dato-adicional name="Ciudad principal" :descripcion="detallesProveedor.ciudad ? detallesProveedor.ciudad.ciudad : '-'" />
            <card-dato-adicional name="Dirección" :descripcion="detallesProveedor.direccion || '-'" />
            <card-dato-adicional name="Correo" :descripcion="detallesProveedor.correo || '-'" />
            <card-dato-adicional name="Descripción" :descripcion="detallesProveedor.descripcion || '-'" />
        </div>
        <!-- opcion 3 datos -->
        <titulo-divisor titulo="Datos adicionales" class=" d-none" />
        <div class="row mx-0 f-14 d-none">
            <div class="col-12 col-lg-6 mb-2">
                <p class="f-600">
                    Nombre del proveedor:
                    <span class="f-300">{{detallesProveedor.nombre}}</span>
                </p>
            </div>
            <div class="col-12 col-lg-6 mb-2">
                <p class="f-600">
                    Contacto:
                    <span class="f-300">{{detallesProveedor.responsable}} </span>
                </p>
            </div>
            <div class="col-12 col-lg-6 mb-2">
                <p class="f-600">
                    Ciudad principal:
                    <span class="f-300">{{detallesProveedor.ciudad ? detallesProveedor.ciudad.ciudad : ''}}</span>
                </p>
            </div>
            <div class="col-12 col-lg-6 mb-2">
                <p class="f-600">
                    Dirección:
                    <span class="f-300">{{detallesProveedor.direccion}}</span>
                </p>
            </div>
            <div class="col-12 col-lg-6 mb-2">
                <p class="f-600">
                    Teléfono:
                    <span class="f-300">{{detallesProveedor.telefono}}</span>
                </p>
            </div>
            <div class="col-12 col-lg-6 mb-2">
                <p class="f-600">
                    Correo:
                    <span class="f-300">{{detallesProveedor.correo}}</span>
                </p>
            </div>
            <div class="col-12 mb-2">
                <p class="f-600"> Descripción: </p>
                <p class="lh-18 f-300">{{detallesProveedor.descripcion}}</p>
            </div>
        </div>
        <!-- archivos -->
        <titulo-divisor titulo="Archivos">
            <el-tooltip content="Añadir documento" placement="bottom-end" effect="light" visible-arrow>
                <i 
                    class="icon-plus-circle f-32 cr-pointer"
                    :class="permitAction('proveedores.agregar.documento') ? 'cr-pointer' : 'cr-not-allowed'" 
                    @click="functionPermitAction('proveedores.agregar.documento', agregarArchivo)"
                />
            </el-tooltip>
        </titulo-divisor>
        <div class="row mx-0">
            <div v-for="(archivo, index) in archivosProveedor" :key="index" class="col-6 col-lg-3 mb-3">
                <card-file :id="archivo.id" :fileName="archivo.nombre" :fileType="archivo.tipo" :file="archivo.archivo" :permiso="'proveedores.descargar.documento'"/>
            </div>
        </div>
        <!-- partials -->
        <modal-adjuntos ref="agregarArchivos" />
    </section>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
export default {
    computed: {
        ...mapGetters({
            detallesProveedor: 'proveedores/ver/ver/provider_details',
            archivosProveedor: 'proveedores/ver/ver/provider_files',
        }),
        id_proveedor(){
            return this.$route.params.id_proveedor
        },
    },
    methods:{
        ...mapActions({
            obtenerArchivosProveedor: 'proveedores/ver/ver/Action_get_provider_files',
        }),
        agregarArchivo(){
            this.$refs.agregarArchivos.toggle()
        }
    },
    async mounted(){
        await this.obtenerArchivosProveedor({id_proveedor: this.id_proveedor})
    }

}
</script>

<style lang="scss" scoped>
.proveedores-ver-informacion{
    height: calc(100vh - 415px);
}
</style>